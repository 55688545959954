import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Logo from '../../Template/Logo';
import Nav from '../../Template/Nav';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';

export default (props) => (
	<BrowserRouter>
		<div className="app">
			<Logo />
			<Nav />
			<Routes />
		</div>
	</BrowserRouter>
);
