import React, { Component, useState } from 'react';
import Main from '../../Template/Main';
import apiClient from '../../services/apiClient';
import { Pagination } from 'antd';
import 'antd/dist/antd.css';
import { Modal, Form, Col, Alert } from 'react-bootstrap';
import { API_ULR } from '../../constants';
import { Row, Button } from 'antd';
import 'antd/dist/antd.css';
import { debounce } from 'lodash';

const headerProps = {
	icon: 'users',
	title: ' Usuários',
	subtitle: 'Listagem, Busca, Criação, Edição e Remoção de Usuários.'
};

const initialState = {
	list: [],
	meta: {},
	showModal: false,
	newUser: { name: '', password: '', username: '' },
	page: 1,
	per_page: 10,
	deleteModal: false,
	showErrorAlert: false,
	loadings: [],
	sucessAlert: false,
	deleteAlert: false,
	fieldAlerts: false
};

export default class UserCrud extends Component {
	state = { ...initialState };

	enterLoading = (index) => {
		this.setState(({ loadings }) => {
			const newLoadings = [ ...loadings ];
			newLoadings[index] = true;

			return {
				loadings: newLoadings
			};
		});
		setTimeout(() => {
			this.setState(({ loadings }) => {
				const newLoadings = [ ...loadings ];
				newLoadings[index] = false;

				return {
					loadings: newLoadings
				};
			});
		}, 1000);
	};

	componentWillMount() {
		this.handlePagination(1, 10);
	}

	renderTable() {
		return (
			<div>
				<table className="table mt-4 table-striped">
					<thead className="thead-dark">
						<tr>
							<th>ID</th>
							<th>NOME</th>
							<th>USUÁRIO</th>
							<th>SENHA</th>
							<th>AÇÕES</th>
						</tr>
					</thead>
					<tbody>{this.renderRows()}</tbody>
				</table>
				<Pagination
					onChange={(page, totalPage) => {
						this.handlePagination(page, totalPage);
					}}
					defaultCurrent={this.state.meta.page}
					total={this.state.meta.per_page * this.state.meta.pages_count}
				/>
			</div>
		);
	}

	showErrorAlert() {
		return (
			<Alert variant="danger" show={this.state.showErrorAlert}>
				Usuário não possuí acesso para executar a ação
			</Alert>
		);
	}

	sucessAlert() {
		return (
			<Alert variant="success" show={this.state.sucessAlert}>
				Adicionado com Sucesso !
			</Alert>
		);
	}

	deleteAlert() {
		return (
			<Alert variant="warning" show={this.state.deleteAlert}>
				Removido com Sucesso
			</Alert>
		);
	}

	fieldAlerts() {
		return (
			<Alert variant="warning" show={this.state.fieldAlerts}>
				Nem todos os campos foram preenchidos, Certifique-se que está correto.
			</Alert>
		);
	}

	renderRows() {
		return this.state.list.map((currentProduct) => {
			return (
				<tr key={currentProduct.id}>
					<td>{currentProduct.id}</td>
					<td>{currentProduct.name}</td>
					<td>{currentProduct.username}</td>
					<td>{currentProduct.password}</td>
					<td>
						<button
							className="btn btn-warning"
							onClick={() => {
								this.setState({ showModal: true, newUser: { ...currentProduct } });
							}}
							style={{ display: 'inline', margin: 'auto' }}
						>
							<i className="fa fa-edit" />
						</button>

						<button
							className="btn btn-danger ml-2"
							onClick={() => {
								this.setState({ deleteModal: true, newUser: { ...currentProduct } });
							}}
							style={{ display: 'inline', margin: 'auto' }}
						>
							<i className="fa fa-trash" />
						</button>
					</td>
				</tr>
			);
		});
	}

	renderForm() {
		return (
			<div className="form">
				<div className="row">
					<div className="col-12 col-md-6">
						<div className="form-group">
							<label>
								<strong>Pesquisa </strong>
							</label>
							&nbsp;&nbsp;
							<i class="fa fa-search" aria-hidden="true" />
							<input
								id="description"
								type="text"
								className="form-control"
								name="name"
								onChange={(event) =>
									this.handleTableSearch(event.target.value, this.state.page, this.state.per_page)}
								placeholder="Pesquise aqui..."
							/>
						</div>
					</div>
				</div>

				<button
					onClick={() => this.handleAdd()}
					className="btn btn-info ml-3 float-right"
					style={{ display: 'inline', marginTop: '-50px', marginRight: '20px' }}
				>
					<i className="fa fa-plus" />
				</button>
			</div>
		);
	}

	handlePagination(page, per_page) {
		/*apiClient.get(`http://34.72.81.199/api/users?page=${page}&per_page=${per_page}`).then((resp) => {*/
		apiClient.get(API_ULR + `/api/users?page=${page}&per_page=${per_page}`).then((resp) => {
			const list = [];
			Object.values(resp.data.registries).map((newUser) => {
				list.push(newUser);
			});
			this.setState({ list: list, meta: resp.data.meta, page: page, per_page: per_page });
		});
	}

	handleClose() {
		this.setState({ showModal: false });
	}

	handleAdd() {
		this.setState({ showModal: true, newUser: { name: '', password: '', username: '' } });
	}

	handleChangeBran(event) {
		this.state.newUser.username = event.target.value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	}

	handleChangeDesc(event) {
		this.state.newUser.password = event.target.value.toLowerCase();
	}

	handleChangeMode(event) {
		this.state.newUser.name = event.target.value;
	}

	handleSendToAPI() {
		const data = [ this.state.newUser ];

		apiClient
			/*.post('http://34.72.81.199/api/users', data)*/
			.post(API_ULR + '/api/users', data)
			.then((resp) => {
				this.handleClose();
				this.setState();
				this.sucessAlert(this.setState({ sucessAlert: true }));
				this.sucessAlert(setTimeout(() => this.setState({ sucessAlert: false }), 1000));
				// this.sucessAlert(this.setState({ sucessAlert: true }));
			})
			.catch((error) => {
				this.handleClose();
				this.showErrorAlert(this.setState({ showErrorAlert: true }));
			});
	}

	handleDeleteToAPI() {
		apiClient
			/*.delete(`http://34.72.81.199/api/users/${this.state.newUser.id}`)*/
			.delete(API_ULR + `/api/users/${this.state.newUser.id}`)
			.then((resp) => {
				this.setState({ deleteModal: false });
				setTimeout(() => window.location.reload(), 800);
				// window.location.reload();
			})
			.catch((error) => {
				this.handleClose();
				this.showErrorAlert(this.setState({ showErrorAlert: true }));
			});
	}

	handleTableSearch(search, page, per_page) {
		/*apiClient.get(`http://34.72.81.199/api/users?page=${page}&per_page=${per_page}&name=${name}`).then((resp) => {*/
		apiClient.get(API_ULR + `/api/users?page=${page}&per_page=${per_page}&search=${search}`).then((resp) => {
			const list = [];
			Object.values(resp.data.registries).map((newUser) => {
				list.push(newUser);
			});
			this.setState({ list: list, meta: resp.data.meta });
		});
	}

	checkInputFields() {
		if (
			this.state.newUser.name === '' ||
			this.state.newUser.password === '' ||
			this.state.newUser.username === ''
		) {
			this.fieldAlerts(this.setState({ fieldAlerts: true }));
		} else {
			this.handleSendToAPI();
			this.fieldAlerts(this.setState({ fieldAlerts: false }));
			setTimeout(() => window.location.reload(), 1300);
		}
	}

	diffLoopCheck() {
		if (this.state.newUser.id) {
			return 0;
		} else if (
			this.state.newUser.name !== '' &&
			this.state.newUser.password !== '' &&
			this.state.newUser.username !== ''
		) {
			return 1;
		}
	}

	//******************************* ENVIAR SOLICITAÇÃO DE ACIONAR NA API 	*******************************//

	showModal() {
		const { loadings } = this.state;
		return (
			<Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
				<Modal.Header closeButton>
					<Modal.Title
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							textAlign: 'center',
							width: '100%'
						}}
					>
						{this.state.newUser.id ? 'Edição' : 'Adição'}
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form validated={true}>
						<Form.Group as={Row}>
							<Form.Label column sm={2}>
								NOME
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									placeholder="Digite o Nome"
									name="name"
									id="name"
									onChange={(e) => this.handleChangeMode(e)}
									defaultValue={this.state.newUser.name}
									required={true}
									onError="É necessário digitar no campo"
								/>
								<Form.Control.Feedback type="invalid">
									É necessário preencher o campo Nome
								</Form.Control.Feedback>
							</Col>
						</Form.Group>
					</Form>
					<Form validated={true}>
						<Form.Group as={Row}>
							<Form.Label column sm={2}>
								USUÁRIO
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									placeholder="Digite o Usuário"
									name="username"
									id="username"
									onChange={(e) => this.handleChangeBran(e)}
									defaultValue={this.state.newUser.username}
									required={true}
									onError="É necessário digitar no campo"
								/>
								<Form.Control.Feedback type="invalid">
									É necessário preencher o campo Usuário
								</Form.Control.Feedback>
							</Col>
						</Form.Group>
					</Form>
					<Form validated={true}>
						<Form.Group as={Row}>
							<Form.Label column sm={2}>
								SENHA
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									placeholder="Digite a senha"
									name="password"
									id="password"
									onChange={(e) => this.handleChangeDesc(e)}
									defaultValue={this.state.newUser.password}
									required={true}
									onError="É necessário digitar no campo"
								/>
								<Form.Control.Feedback type="invalid">
									É necessário preencher o campo Senha
								</Form.Control.Feedback>
							</Col>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button
						size="middle"
						loading={this.diffLoopCheck()}
						type="primary"
						onClick={(e) => {
							this.checkInputFields(this.state.newUser);
							this.enterLoading(1);
						}}
					>
						Adicionar
					</Button>

					<Button type="danger" size="middle" onClick={() => this.setState({ showModal: false })}>
						Cancelar
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	deleteModal() {
		return (
			<Modal show={this.state.deleteModal} onHide={() => this.setState({ deleteModal: false })}>
				<Modal.Header closeButton>
					<Modal.Title
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							textAlign: 'center',
							width: '100%'
						}}
					>
						{'Confirme a remoção do item'}
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form>
						<Form.Group as={Row}>
							<Form.Label column sm={2}>
								Login
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									name="username"
									id="username"
									value={this.state.newUser.username}
								/>
							</Col>
						</Form.Group>
					</Form>
					<Form>
						<Form.Group as={Row}>
							<Form.Label column sm={2}>
								Senha
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									name="password"
									id="password"
									value={this.state.newUser.password}
								/>
							</Col>
						</Form.Group>
					</Form>
					<Form>
						<Form.Group as={Row}>
							<Form.Label column sm={2}>
								Nome
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									placeholder="Digite o Nome do Usuário"
									name="name"
									id="name"
									value={this.state.newUser.name}
								/>
							</Col>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button
						onClick={() => {
							this.handleDeleteToAPI();
							this.deleteAlert(this.setState({ deleteAlert: true }));
							this.deleteAlert(setTimeout(() => this.setState({ deleteAlert: false }), 4000));
						}}
						size="middle"
						type="danger"
					>
						Deletar
					</Button>

					<Button type="primary" size="middle" onClick={() => this.setState({ deleteModal: false })}>
						Cancelar
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	render() {
		return (
			<Main {...headerProps}>
				{this.showErrorAlert()}
				{this.sucessAlert()}
				{this.deleteAlert()}
				{this.fieldAlerts()}
				{this.renderForm()}
				{this.renderTable()}
				{this.showModal()}
				{this.deleteModal()}
			</Main>
		);
	}
}
