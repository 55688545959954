import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Machineries from '../user/Machineries';
import Users from '../user/Users';
import Products from '../user/Products';
import Login from '../../login/LoginPage';
import Email from '../user/Email';
import Relatorios from '../user/relatorios';
import { isAuthenticated } from '../user/auth';

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			isAuthenticated() ? (
				<Component {...props} />
			) : (
				<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
			)}
	/>
);

export default (props) => (
	<Switch>
		<PrivateRoute path="/Users" component={Users} />
		<PrivateRoute path="/machineries" component={Machineries} />
		<PrivateRoute path="/products" component={Products} />
		<PrivateRoute path="/email" component={Email} />
		<PrivateRoute path="/reports" component={Relatorios} />
		<Route path="/login" component={Login} />
		<Redirect from="*" to="/login" />
	</Switch>
);
//<Route path="/users" component={UserMembers} />
