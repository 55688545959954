import React, { Component } from 'react';
import { Form, Row, Col, Button, Jumbotron } from 'react-bootstrap';
import apiClient from '../services/apiClient';
import { isAuthenticated } from '../Componentes/user/auth';
import { API_ULR } from '../constants';
import { Input, Space, Alert } from 'antd';
import 'antd/dist/antd.css';

const initialState = {
	username: '',
	password: '',
	showErrorAlert: false
};

export default class LoginPage extends Component {
	state = { ...initialState };

	LoginDiv() {
		return (
			<Jumbotron style={{ margin: '10px' }} fluid>
				<h2 className="text-center">Login Usuário</h2>

				<Form
					validated={true}
					style={{
						margin: '90px 50px 65px -28px',
						textAlign: 'center',
						alignContent: 'center',
						padding: '100px',
						marginRight: '138px'
					}}
				>
					<Form.Group as={Row} controlId="username">
						<Form.Label column sm={5}>
							Usuário
						</Form.Label>
						<Col sm={4}>
							<Form.Control
								type="text"
								name="username"
								maxLength={15}
								minLength={3}
								required={true}
								pattern={'[A-Za-z]+'}
								onChange={(e) => this.handleOnChangeusername(e)}
								onError="Digite apenas Letras"
								style={{ borderRadius: '0px' }}
							/>

							<Form.Control.Feedback type="invalid">Digite um Usuário válido</Form.Control.Feedback>
						</Col>
					</Form.Group>

					<Form.Group as={Row} controlId="password">
						<Form.Label column sm={5}>
							Senha
						</Form.Label>
						<Col sm={4}>
							{/* <Form.Control
								type="password"
								placeholder="Digite a senha"
								name="password"
								maxLength={15}
								minLength={3}
								// onChange={(e) => this.handleOnChangePassword(e)}
								required={true}
							/> */}
							{/* <Form.Control.Feedback type="invalid">Senha inválida</Form.Control.Feedback> */}
							<Input.Password
								onChange={(e) => this.handleOnChangePassword(e)}
								maxLength={15}
								minLength={3}
								size="middle"
								onPressEnter={(e) => this.sendLoginRequestToAPI()}
								required={true}
								style={{ padding: '7px 14px', color: '#212529', fontWeight: '400', fontSize: '1rem' }}
							/>
							<Form.Control.Feedback type="invalid">Senha inválida</Form.Control.Feedback>
						</Col>
					</Form.Group>
					<Button
						variant="info"
						onClick={() => {
							this.sendLoginRequestToAPI();
						}}
						style={{ display: 'inline', marginLeft: '230px' }}
					>
						LOGAR
					</Button>
				</Form>
			</Jumbotron>
		);
	}

	showErrorAlert() {
		return <Alert message="Erro usuário não acadastrado" type="error" showIcon />;
	}

	handleOnChangePassword(event) {
		this.state.password = event.target.value;
		console.log(event.target.value);
	}
	handleOnChangeusername(event) {
		this.state.username = event.target.value;
		console.log(event.target.value);
	}

	sendLoginRequestToAPI() {
		this.setState();
		const data = this.state;
		console.log(data);

		apiClient
			/*.post('http://34.72.81.199/api/login', data)*/
			.post(API_ULR + '/api/login', data)
			.then((resp) => {
				console.log(resp.data);
				localStorage.setItem('token', resp.data.token);
				isAuthenticated();
				window.location.pathname = '/users';
			})
			.catch((error) => {
				console.error(error.data);
				this.showErrorAlert(this.setState({ showErrorAlert: true }));
				alert(`Usuário ou senha inválidos`);
			});
	}

	render() {
		return this.LoginDiv();
	}
}

/*API PARA PRODUÇÃO
http://35.224.137.171:8000 */
