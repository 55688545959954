import axios from 'axios';

const instance = axios.create({
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}
});

instance.interceptors.request.use((config) => {
	const token = localStorage.getItem('token');

	if (!config.headers.Authorization && token) {
		config.headers = {
			...config.headers,
			Authorization: `Bearer ${token}`
		};
	}

	return config;
});

//eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZ…1ODR9.bU3FjsAuUh68g4QlwgLuurdKgnoUNO4NeoaIe-FP--s
export default instance;
