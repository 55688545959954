import React from 'react';
import './Nav.css';
import { Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';

export default (props) => (
	<aside className="menu-area">
		<nav className="menu">
			<Link to="/email">
				<i className="fa fa-envelope" style={{ fontSize: '1.1rem' }}>
					{' '}
					E-Mail
				</i>
			</Link>

			<Link to="/machineries">
				<i className="fa fa-truck" style={{ fontSize: '1.1rem' }}>
					{' '}
					Maquinário
				</i>
			</Link>

			<Link to="/users">
				<i className="fa fa-users" style={{ fontSize: '1.1rem' }}>
					{' '}
					Usuários
				</i>
			</Link>

			<Link to="/products">
				<i className="fa fa-flask" style={{ fontSize: '1.1rem' }}>
					{' '}
					Produtos
				</i>
			</Link>
			<Link to="/reports">
				<i className="fa fa-file-excel-o" style={{ fontSize: '1.1rem' }}>
					{' '}
					Relatórios CSV
				</i>
			</Link>
		</nav>
	</aside>
);
