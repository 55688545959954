import React, { Component } from 'react';
import Main from '../../Template/Main';
import apiClient from '../../services/apiClient';
import { Pagination } from 'antd';
import 'antd/dist/antd.css';
import { Modal, Button, Form, Row, Col, Alert } from 'react-bootstrap';
import { API_ULR } from '../../constants';
const moment = require('moment');
require('moment/locale/pt-br');

const headerProps = {
	icon: 'file-excel-o',
	title: ' Relatórios CSV',
	subtitle: 'Listagem, Busca, e Download de Relatórios CSV'
};

const initialState = {
	list: [],
	meta: {},
	showModal: false,
	newSheets: { name: '', user_name: '', created_at: '' },
	page: 1,
	per_page: 10,
	deleteModal: false,
	showErrorAlert: false
};

export default class UserCrud extends Component {
	state = { ...initialState };

	componentWillMount() {
		this.handlePagination(1, 10);
	}

	showErrorAlert() {
		return (
			<Alert variant="danger" show={this.state.showErrorAlert}>
				Usuário não possuí acesso para executar a ação
			</Alert>
		);
	}

	renderTable() {
		return (
			<div>
				<table className="table mt-4  table-striped">
					<thead className="thead-dark">
						<tr>
							<th>Usuário</th>
							<th>Nome do Arquivo</th>
							<th>Data</th>

							<th>Ações</th>
						</tr>
					</thead>
					<tbody>{this.renderRows()}</tbody>
				</table>
				<Pagination
					onChange={(page, totalPage) => {
						this.handlePagination(page, totalPage);
					}}
					defaultCurrent={this.state.meta.page}
					total={this.state.meta.per_page * this.state.meta.pages_count}
				/>
			</div>
		);
	}

	renderRows() {
		return this.state.list.map((currentReport) => {
			return (
				<tr key={currentReport.id}>
					<td>{currentReport.user_name}</td>
					<td>{currentReport.name}</td>
					<td>{moment(currentReport.created_at).utc().format('dddd, D [de] MMMM [de] YYYY [às] LT')}</td>

					<td>
						<button
							className="btn btn-success"
							onClick={() => {
								this.handleGetReportDownload(currentReport);
							}}
						>
							<i className="fa fa-download" />
						</button>
					</td>
				</tr>
			);
		});
	}

	renderForm() {
		return (
			<div className="form">
				<div className="row">
					<div className="col-12 col-md-6">
						<div className="form-group">
							<label>
								<strong>Pesquisa </strong>
							</label>
							&nbsp;&nbsp;
							<i class="fa fa-search" aria-hidden="true" />
							<input
								id="email"
								type="text"
								className="form-control"
								name="search"
								onChange={(event) =>
									this.handleTableSearch(event.target.value, this.state.page, this.state.per_page)}
								placeholder="Pesquise aqui..."
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	handlePagination(page, per_page) {
		/*apiClient.get(`http://34.72.81.199/api/sheets?page=${page}&per_page=${per_page}`).then((resp) => {*/
		apiClient.get(API_ULR + `/api/sheets?page=${page}&per_page=${per_page}`).then((resp) => {
			const list = [];
			Object.values(resp.data.registries).map((newSheets) => {
				list.push(newSheets);
			});
			this.setState({ list: list, meta: resp.data.meta, page: page, per_page: per_page });
		});
	}

	handleClose() {
		this.setState({ showModal: false });
	}

	handleAdd() {
		this.setState({ showModal: true, newSheets: { email: '' } });
	}

	handleChangeBran(event) {
		this.state.newSheets.email = event.target.value.toLowerCase();
	}

	handleSendToAPI() {
		const data = [ this.state.newSheets ];
		apiClient
			/*.post('http://34.72.81.199/api/sheets', data)*/
			.post(API_ULR + '/api/sheets', data)
			.then((resp) => {
				this.handleClose();
				this.setState();
				window.location.reload();
			})
			.catch((error) => {
				this.handleClose();
				this.showErrorAlert(this.setState({ showErrorAlert: true }));
			});
	}

	handleDeleteToAPI() {
		apiClient
			/*.delete(`http://34.72.81.199/api/sheets/${this.state.newSheets.id}`)*/
			.delete(API_ULR + `/api/sheets/${this.state.newSheets.id}`)
			.then((resp) => {
				this.setState();
				window.location.reload();
			})
			.catch((error) => {
				this.handleClose();
				this.showErrorAlert(this.setState({ showErrorAlert: true }));
			});
	}

	handleTableSearch(search, page, per_page) {
		apiClient
			/*.get(`http://34.72.81.199/api/sheets?page=${page}&per_page=${per_page}&email=${email}`)*/
			.get(API_ULR + `/api/sheets?page=${page}&per_page=${per_page}&search=${search}`)
			.then((resp) => {
				const list = [];
				Object.values(resp.data.registries).map((newSheets) => {
					list.push(newSheets);
				});
				this.setState({ list: list, meta: resp.data.meta });
			});
	}

	handleGetReportDownload(report) {
		apiClient
			.get(API_ULR + `/api/download/sheets/${report.id}`)
			.then((resp) => {
				let blob = new Blob([ '\uFEFF', resp.data ], {
					type: 'text/csv;charset=utf-8'
				});
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement('a');
				a.href = url;
				a.download = report.name;
				a.click();
			})
			.catch((error) => {});
	}

	//******************************* ENVIAR SOLICITAÇÃO DE ACIONAR NA API 	*******************************//

	showModal() {
		return (
			<Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
				<Modal.Header closeButton>
					<Modal.Title
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							textAlign: 'center',
							width: '100%'
						}}
					>
						{this.state.newSheets.id ? 'Edição' : 'Adição'}
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form>
						<Form.Group as={Row}>
							<Form.Label column sm={2}>
								E-Mail
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="email"
									placeholder="Digite o Email"
									name="email"
									id="email"
									onChange={(e) => this.handleChangeBran(e)}
									defaultValue={this.state.newSheets.email}
									pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
									required={true}
								/>
							</Col>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={(e) => this.handleSendToAPI(e)} variant="success">
						Adicionar
					</Button>

					<Button variant="danger" onClick={() => this.setState({ showModal: false })}>
						Cancelar
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	deleteModal() {
		return (
			<Modal show={this.state.deleteModal} onHide={() => this.setState({ deleteModal: false })}>
				<Modal.Header closeButton>
					<Modal.Title
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							textAlign: 'center',
							width: '100%'
						}}
					>
						{'Confirme a remoção do item'}
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form>
						<Form.Group as={Row}>
							<Form.Label column sm={2}>
								E-mail
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									name="email"
									id="email"
									onChange={(e) => this.handleChangeBran(e)}
									defaultValue={this.state.newSheets.email}
								/>
							</Col>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => this.handleDeleteToAPI()} variant="danger">
						Deletar
					</Button>

					<Button variant="info" onClick={() => this.setState({ showModal: false })}>
						Cancelar
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	render() {
		return (
			<Main {...headerProps}>
				{this.showErrorAlert()}
				{this.renderForm()}
				{this.renderTable()}
				{this.showModal()}
				{this.deleteModal()}
			</Main>
		);
	}
}
