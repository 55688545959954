import './Logo.css';
import React from 'react';

export default (props) => (
	<div id="logo" className="textbox">
		<p id="logo">
			AC{' '}
			<small id="subtitle" className="subtitle">
				Manutenção
			</small>
		</p>
	</div>
);
